package dev.moetz.chatoverlay.model.thirdpartyemote

import dev.moetz.chatoverlay.model.EmoteProvider
import kotlinx.serialization.Serializable

@Serializable
data class EmoteProviderReloadEvent(
    val channelName: String,
    val provider: EmoteProvider?
)
