package dev.moetz.chatoverlay.irc

import io.ktor.client.*
import io.ktor.client.plugins.logging.*
import io.ktor.client.plugins.websocket.*
import io.ktor.websocket.*
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlin.time.Duration.Companion.seconds

class IRC(
    private val host: String,
    private val port: Int,
    private val path: String,
    private val loggingEnabled: Boolean,
) {

    private val httpClient: HttpClient by lazy {
        HttpClient() {
            install(WebSockets) {
                pingInterval = 15.seconds
            }
            if (loggingEnabled) {
                install(Logging)
            }
        }
    }

    private var shouldBeActive: Boolean = false
    private var webSocketSession: ClientWebSocketSession? = null
    private var connectionJob: Job? = null

    private val mutableConnectedStateFlow: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val connectedStateFlow: StateFlow<Boolean> = mutableConnectedStateFlow.asStateFlow()

    private val mutableIncomingMessagesFlow: MutableSharedFlow<String> = MutableSharedFlow()
    val incomingMessagesFlow: SharedFlow<String> = mutableIncomingMessagesFlow.asSharedFlow()

    suspend fun connect() {
        shouldBeActive = true
        connectionJob = GlobalScope.launch {
            val scope = this
            httpClient.webSocket(urlString = "wss://$host:$port/$path") {
                webSocketSession = this
                mutableConnectedStateFlow.emit(true)

                scope.launch {
                    val reason = this@webSocket.closeReason.await()
                    println("[IRC] closeReason: $reason")
                    mutableConnectedStateFlow.emit(false)
                    shouldBeActive = false
                }

                while (shouldBeActive) {
                    val othersMessage = incoming.receive() as? Frame.Text ?: continue
                    val incoming = othersMessage.readText()
                    if (loggingEnabled) {
                        println(incoming)
                    }
                    mutableIncomingMessagesFlow.emit(incoming)
                }
            }
        }
        mutableConnectedStateFlow.first { it == true }
    }

    suspend fun send(text: String) {
        webSocketSession?.send(Frame.Text(text))
    }

    suspend fun join(channels: List<String>) {
        webSocketSession?.send(Frame.Text("JOIN ${channels.joinToString(separator = ",") { "#$it" }}"))
    }

    suspend fun disconnect() {
        connectionJob?.cancel()
        connectionJob = null
        mutableConnectedStateFlow.value = false
        shouldBeActive = false
        webSocketSession?.cancel()
        webSocketSession = null
    }


}